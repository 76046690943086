<template>
    <v-container fluid style="margin-bottom:200px">
        <v-row>
            <v-col cols="12">
                <v-breadcrumbs :items="breadcumbs">
                    <template v-slot:item="{ item }">
                        <v-breadcrumbs-item
                            :to="item.href"
                            class="text-subtitle-2 crumb-item"
                            :disabled="item.disabled"
                            exact
                        >
                            {{ item.text }}
                        </v-breadcrumbs-item>
                    </template>
                </v-breadcrumbs>
                <v-snackbar v-model="snackbar.visible" :color="snackbar.color" :multi-line="snackbar.mode === 'multi-line'" :timeout="snackbar.timeout" :top="snackbar.position === 'top'">
                    <v-layout align-center pr-4>
                        <v-icon class="pr-3" dark large>{{ snackbar.icon }}</v-icon>
                        <v-layout column>
                            <div>
                            <strong>{{ snackbar.title }}</strong>
                            </div>
                            <div>{{ snackbar.text }}</div>
                        </v-layout>
                    </v-layout>
                    <v-btn v-if="snackbar.timeout === 0" icon @click="snackbar.visible = false">
                        <v-icon>clear</v-icon>
                    </v-btn>
                </v-snackbar>
            </v-col>
        </v-row>
        <v-row class="mt-5">
            <v-col class="col-12" xs="12" sm="5" md="5" v-if="cropImg">
                <div class="img_container" style="text-align:center; border: solid 2px #dddddd; cursor: pointer; width:100%; height: 500px;position: relative;">
                    <img :src="cropImg" class="img" style="width: 100%;">
                    <button class="btn btn-danger"  @click="hapusImage()" style="
                        position: absolute;
                        z-index: 1;
                        top: 5px;
                        right: 10px;">
                        <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 24 24" fill="none" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-trash-2"><polyline points="3 6 5 6 21 6"></polyline><path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path><line x1="10" y1="11" x2="10" y2="17"></line><line x1="14" y1="11" x2="14" y2="17"></line></svg>
                    </button>
                </div>
                
            </v-col>
            <v-col class="col-12" xs="12" sm="5" md="5" v-else>
                <div @click="choiceImage()" class="img_container" style="text-align:center; border: dashed 2px #dddddd; cursor: pointer; width:100%; height: 500px;">
                    <svg xmlns="http://www.w3.org/2000/svg" width="80" height="80" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-image my-auto"><rect x="3" y="3" width="18" height="18" rx="2" ry="2"></rect><circle cx="8.5" cy="8.5" r="1.5"></circle><polyline points="21 15 16 10 5 21"></polyline></svg>
                </div>
            </v-col>
            <v-col class="col-12" xs="12" sm="2" md="1">
                <div class="img_container" style="text-align:center;width:100%; height: 500px;cursor: pointer;" @click="convertToText()">
                    <v-icon x-large >
                        mdi-arrow-right-bold
                    </v-icon>
                </div>
            </v-col>
            <v-col class="col-12" xs="12" sm="6" md="6">
                <v-textarea
                outlined
                auto-grow
                row-height="98"
                v-model="textConvert"
                ></v-textarea>
            </v-col>    
        </v-row>
        <v-dialog
            v-model="dialog"
            max-width="700px"
            persistent
        >
            <v-card class="p-3 rounded-xl">
                <v-card-title class="text-center">
                    <v-row align="center" justify="center">
                        <v-col cols="10">
                        <h5 style="font-weight:700; color: #1e4393;">
                        Pilih Gambar
                        </h5>
                        </v-col>
                    </v-row>  
                </v-card-title>
                <v-card-text>
                    <v-container fluid class="fill-height">
                        <v-row>
                            <v-col cols="12" xs="12" sm="12" md="12">
                                <h6>
                                    Pilih Gambar
                                </h6>

                                <div v-if="imgSrc" style="width: auto; height:500px; border: 1px solid gray; display: inline-block;">
                                    <vue-cropper
                                        ref='cropper'
                                        :guides="true"
                                        :view-mode="2"
                                        drag-mode="crop"
                                        :background="true"
                                        :rotatable="true"
                                        :src="imgSrc"
                                        :img-style="{ 'width': 'auto', 'height': '500px' }">
                                    </vue-cropper>
                                </div>

                                <div v-else>
                                    <input type="file" name="image" id="image" @change="setImage" class="form-control" accept="image/*">
                                </div>
                            </v-col>
                        </v-row> 
                    </v-container>
                </v-card-text>
                <v-card-actions class="text-center">
                    <v-row justify="center">
                        <v-col cols="12" xs="12" sm="3" md="4">
                            <v-btn block class="rounded-l text-white p-4" color="#003871" @click="saveImage()">Simpan</v-btn>
                        </v-col>
                    </v-row>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-container>
</template>
<script>
import VueCropper from 'vue-cropperjs'
import 'cropperjs/dist/cropper.css'
import { createWorker } from 'tesseract.js';

export default {
    components:{
        VueCropper
    },
    data() {
        return {
            raw_image: '',
            imgSrc: '',
            imgAvatar: '',
            cropImg: '',
            snackbar: {
                color: null,
                icon: null,
                mode: null,
                position: "top",
                text: null,
                timeout: 7500,
                title: null,
                visible: false
            },
            breadcumbs: [
                {
                text: 'AYOLA',
                disabled: false,
                href: '/admin/ayala',
                },
                {
                text: 'Setting',
                disabled: true,
                href: 'breadcrumbs_link_2',
                },
                {
                text: 'Scan Image to Text',
                disabled: true,
                href: 'breadcrumbs_link_2',
                },
            ],
            loading: false,
            dialog: false,
            textConvert: ''
        }
    },
    mounted(){
    },
    methods:{
        reset() {
            this.imgSrc = ''
            this.cropImg = ''
		},
        async choiceImage(){
            this.dialog = true
        }, 
        setImage(e) {
			this.reset()
			const file = e.target.files[0]
			if (!file.type.includes('image/')) {
				alert('Please select an image file');
				return;
			}
			if (typeof FileReader === 'function') {
				const reader = new FileReader()
				reader.onload = (event) => {
					this.imgSrc = event.target.result
				};
				reader.readAsDataURL(file)
			} else {
				alert('Sorry, FileReader API not supported')
			}
		},
        async saveImage(){
            this.cropImg = this.imgSrc ? this.$refs.cropper.getCroppedCanvas().toDataURL() : ''
            this.dialog = false
        },
        async convertToText(){
            this.$store.dispatch('setOverlay', true)

            const worker = await createWorker({
                logger: m => console.log(m)
            });

            await worker.loadLanguage('eng');
            await worker.initialize('eng');
            const { data: { text } } = await worker.recognize(this.cropImg ? this.cropImg : 'https://tesseract.projectnaptha.com/img/eng_bw.png');
            console.log(text);
            this.textConvert = text
            this.$store.dispatch('setOverlay', false)
            await worker.terminate();
        },
        hapusImage(){
            this.textConvert = ''
            this.imgSrc = ''
            this.cropImg = ''
        }
    },
    watch: {
    }
}
</script>
<style scoped>
.img_container > svg {
    position:relative;
    top: calc(50% - 10px); /* 50% - 3/4 of icon height */
}

.img_container > i {
    position:relative;
    top: calc(50% - 10px); /* 50% - 3/4 of icon height */
}
</style>